.static-date-picker {
  // width: 320px;
  .MuiPickerStaticWrapper-content {
    border-radius: 0.5rem;
  }
  .PrivatePickersFadeTransitionGroup-root {
    overflow: hidden;
  }
  .PrivatePickersSlideTransition-root {
    min-height: 230px;
  }
  .MuiYearPicker-root {
    min-height: 230px;
  }
}
