// -----border-radius-----
@each $key, $value in $borderRadius {
  .br-#{$key} {
    border-radius: $value * $spacing;
  }
}

.border {
  border: 1px solid var(--grey-300);
}
