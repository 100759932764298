// VARIABLES
@import './common/variable';

// COMMON UTILITIES
@import './common/common';

// VENDOR & THIRD PARTY
@import './vendor/chart';
@import './vendor/simplebar';
@import './vendor/react-date-range';
@import './mui/input';
@import './mui/select';
@import './mui/table';
@import './mui/datepicker';
@import './mui/badge';

// COMPONENTS
@import './components/vendor';

// TICKET
@import './ticket/_audio';

// LANDING
@import './landing/wave';

@import './landing/aiControllerWave';

.mini-drop {
  > div {
    padding: 5px;
    > div {
      padding: 0;
    }
  }
}
