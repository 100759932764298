.badge-center {
  .MuiBadge-badge {
    top: 50%;
    left: 2px;
  }
  &.badge-white {
    .MuiBadge-badge {
      background-color: #fff;
      color: var(--primary-main);
    }
  }
}
