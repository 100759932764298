.mtable {
  // diable shadow in table
  box-shadow: unset;

  .MuiToolbar-root {
    min-height: 40px;
    padding: 0 8px;
  }
  .MuiIconButton-root {
    padding: 0;
    &:hover {
      background-color: transparent;
    }
  }

  .MuiTablePagination-toolbar {
    .MuiBox-root span {
      &:nth-child(2) {
        margin: 0 1rem;
      }
      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(5) {
        display: none;
      }
    }
  }

  thead {
    .MuiTableCell-root {
      background-color: var(--grey-200);
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  // reduce padding on left from 24 to 12
  .MuiTableCell-root:first-of-type {
    padding-left: 12px;
  }

  // remove border from bottom
  .MuiTableFooter-root {
    .MuiTableCell-footer {
      border: unset;
    }
  }

  // disabled edit delete
  &.invite-table {
    .MuiTableRow-root {
      .MuiTableCell-root:last-child {
        div {
          justify-content: space-between;
          padding-right: 8px;
        }
      }
    }
  }
}

.MuiTableCell-alignCenter {
  & > div {
    justify-content: center;
  }
}
