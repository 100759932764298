// body {
//   background-color: #000000;
//   overflow: hidden;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

//  body,
//  html {
//   height: 100%;
//   width: 100%;
//   margin: 0;
//   padding: 0;
//  }

#mainSVG {
  width: 100%;
  height: 100%;
  visibility: hidden;
}

.ell, #ai {
  fill: #F7F8FA;
  opacity: 0.9;
}