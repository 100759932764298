$size: 50px;
$circle-count: 50;
$speed: 2s;
// html,body{
//   width: 100%;height: 100%;
// }
.wavewrapper{
  // background-color: #140032;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin:0;
  *,*:after,*:before{
    position: absolute;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // margin: auto;
  }
  width: 100%;height: 100%;
}

.hexagon{
  width: $size;
  height: calc($size / 2);
  &, .hexagon-span{
    border-top-left-radius: $size;
    border-bottom-right-radius: $size;
  }
  .hexagon-span{
    animation: hexagon $speed linear infinite;
    &:after,&:before{
      content: '';
      background-color: currentColor;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    &:before{
      transform: translate(calc($size / 4), -1 * calc($size / 4));
    }
    &:after{
      transform: translate(-1 * calc($size / 4), calc($size / 4));
    }    
  }
  @for $i from 1 through $circle-count{    
    &:nth-child(#{$i}){
      // $color: $i * calc(360 / $circle-count);
      transform: rotate(-$i * calc(360deg / $circle-count)) translate($size + 100px);
      // color: hsla($color,100%,50%,1);
      .hexagon-span{
        animation-delay: -$i * $speed * calc(3 / $circle-count);
        // background-color: hsla($color,100%,50%,0.5);
      }
    }
  }
}
@keyframes hexagon {
  100%{
    transform: rotate(360deg);
  }
}