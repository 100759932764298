// mui customisation, borderless input
.input-flush fieldset {
  display: none;
}

// td > input, bottom border input, color change for success and error state
.MuiTableCell-root {
  .MuiInput-underline:not(.Mui-error):after {
    border-bottom-color: var(--focused-color) !important;
  }
}

.MuiTextField-root {
  &.flat,
  &.flush {
    .MuiInputLabel-root {
      transform: translate(12px, -9px) scale(0.75);
      // &[data-shrink='true'] {
      //   display: none;
      // }
    }
    .MuiFilledInput-underline {
      padding-top: 0;
      border-radius: 0.5rem;
      &:before,
      &:after {
        display: none;
      }
    }

    .MuiFilledInput-input {
      padding: 12px 4px;
    }
  }

  &.flush {
    .MuiInputLabel-root {
      &[data-shrink='true'] {
        display: none;
      }
    }
  }
}
