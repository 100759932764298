// tooltip
.apexcharts-tooltip {
  backdrop-filter: blur(6px);
  .apexcharts-tooltip-title {
    border: none !important;
    background-color: var(--grey-0) !important;
    font-weight: 700;
  }
  &.apexcharts-theme-light {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3) !important;
    border: none !important;
    background-color: rgba(255, 255, 255, 0.7) !important;
  }
  &.apexcharts-theme-dark {
    box-shadow: none;
  }
}

// legend
.apexcharts-legend-series {
  display: inline-flex !important;
}
