// -----position-----
$positions: (
  a: absolute,
  f: fixed,
  r: relative,
  s: sticky,
);
@each $key, $value in $positions {
  .p-#{$key} {
    position: $value;
  }
}

// -----top, right, bottom , left-----
.t-0 {
  top: 0;
}
.t-50 {
  top: 50%;
}
.t-60 {
  top: 60px;
}
.r-0 {
  right: 0;
}
.b-0 {
  bottom: 0;
}
.b-10 {
  bottom: 10px;
}
.l-0 {
  left: 0;
}
.tty--50 {
  transform: translateY(-50%);
}
.ttx--50 {
  transform: translateX(-50%);
}
