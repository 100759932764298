.bs-cover {
  background-size: cover;
}

.bg-primary-main {
  background-color: var(--primary-main);
}
.bg-secondary-main {
  background-color: var(--secondary-main);
}
.bg-grey-0 {
  background-color: var(--grey-0);
}
.bg-grey-200 {
  background-color: var(--grey-200);
}
.op-bottom {
  object-position: bottom;
}

.color-primary {
  color: var(--primary-main);
}
.color-secondary {
  color: var(--secondary-main);
}
