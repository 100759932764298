*,
*::before,
*::after {
  box-sizing: border-box;
}

// Tables
//
// Prevent double borders

html {
  height: 100%;
}

body {
  min-height: 100%;
}

// Lists

ul,
ol {
  padding-left: 2.5rem;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

// Remove the inner padding in Chrome and Safari on macOS.
::-webkit-search-decoration {
  -webkit-appearance: none;
}

// Remove the cancel buttons in Chrome and Safari on macOS.
[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

::-webkit-inner-spin-button {
  height: auto;
}

// Remove the default `border-radius` that macOS Chrome adds.
// See https://github.com/twbs/bootstrap/issues/24093

button {
  // stylelint-disable-next-line property-disallowed-list
  border-radius: 0;
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
// 3. Opinionated: add "hand" cursor to non-disabled button elements.
button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; // 2
  &:not(:disabled) {
    cursor: pointer; // 3
  }
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

// 1. Textareas should really only resize vertically so they don't break their (horizontal) containers.
textarea {
  resize: vertical; // 1
}

// Inherit font family and line height for file input buttons
::file-selector-button {
  font: inherit;
}

// 1. Change font properties to `inherit`
// 2. Correct the inability to style clickable types in iOS and Safari.

::-webkit-file-upload-button {
  font: inherit; // 1
  -webkit-appearance: button; // 2
}

//
// reboot.scss
// Extended from Bootstrap
//

//
// Remove the cancel buttons in Chrome and Safari on macOS.
//

[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
