$spacing: 8px !default;

$spacings: (
  0: 0,
  '05': 0.5,
  1: 1,
  '15': 1.5,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
);

$borderRadius: (
  0: 0,
  '05': 0.5,
  1: 1,
  '15': 1.5,
  2: 2,
  3: 3,
);

$zindexs: (
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  10: 10,
  100: 100,
  999: 999,
  1000: 1000,
  9999: 9999,
);
