// -----z-index-----
@each $zindex, $value in $zindexs {
  .zindex-#{$zindex} {
    z-index: $value;
  }
}

// -----overflow-----
.overflow-hidden {
  overflow: hidden;
}

// -----opacity-----
$opacitys: 0, 50, 75, 100;
@each $value in $opacitys {
  .o-#{$value} {
    opacity: $value * 0.01;
  }
}

// -----overflow-----
.of-h {
  overflow: hidden;
}
.of-a {
  overflow: auto;
}

.c-p {
  cursor: pointer;
}
