.audio-recorder {
  background-color: #ebebeb;
  box-shadow: 0 2px 5px #bebebe;
  border-radius: 20px;
  box-sizing: border-box;
  width: 100% !important;
  display: flex;
  align-items: center;
  padding: 12px;
  transition: all .2s ease-in
}

.audio-recorder-hide {
  background-color: transparent !important;
  box-shadow: 0 0 0 0 !important;
  border-radius: 0px !important;
  box-sizing: content-box !important;
  width: 0px !important;
  display: none !important;
  align-items: center;
  padding: 0px !important;
  transition: all .2s ease-in
}

.audio-recorder-mic {
  cursor: pointer;
  height: 16px;
  color: #000
}

.audio-recorder-timer,
.audio-recorder-status {
  margin-left: 10px;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1
}

.audio-recorder-status {
  margin-left: 15px;
  display: flex;
  align-items: baseline;
  flex-grow: 1;
  animation-name: fading-ar-status;
  animation-duration: 2s;
  animation-iteration-count: infinite
}

.audio-recorder-status-dot {
  background-color: #d00;
  border-radius: 50%;
  height: 10px;
  width: 9px;
  margin-right: 5px
}

.audio-recorder-options {
  height: 16px;
  margin-left: 10px;
  cursor: pointer
}

.recording {
  border-radius: 12px;
  width: 300px;
  transition: all .2s ease-out
}

.display-none {
  display: none
}

@keyframes fading-ar-status {
  0% {
    opacity: 1
  }

  50% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}