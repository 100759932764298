// chnage color of focused border color

.MuiInputLabel-outlined {
  &.Mui-focused:not(.Mui-error) {
    color: var(--focused-color);
  }
}

.MuiOutlinedInput-root {
  &:not(.Mui-error) {
    .MuiSelect-outlined ~ fieldset {
      border-color: var(--grey-300);
    }
  }

  &.Mui-focused:not(.Mui-error) {
    .MuiSelect-outlined ~ fieldset {
      border-color: var(--focused-color);
    }
  }
}

.Mui-error {
  &.Mui-focused ~ .texts * {
    color: var(--error-main);
  }

  ~ .texts .MuiFormHelperText-root {
    color: var(--error-main);
  }

  &.MuiFormHelperText-root {
    margin-left: 0;
  }
}
